const t = {
    icon: 'fa-user-tie',
    menus: {
        main: { section: 'accounts', priority: 40 },
    },
    new: {
        attributes: ['email', 'lastName', 'firstName', 'password', 'role', 'suspended'],
        content: [['row', [['block', ['!$email!', '$lastName', '$firstName', '$password!', '$role!', '$suspended']]]], '$submit'],
    },
    edit: {
        attributes: ['email', 'lastName', 'firstName', 'role', 'suspended'],
        content: [['row', [['block', ['!$email!', '$lastName', '$firstName', '$role', '$suspended']]]], '$submit'],
    },
    display: {
        properties: ['id', 'firstName', 'lastName', 'email', 'role'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            { id: 'email', label: 'column_email_label' },
            { id: 'lastName', label: 'column_lastname_label' },
            { id: 'firstName', label: 'column_firstname_label' },
            { id: 'role', label: 'column_role_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;
