const t = {
    icon: 'fa-clipboard',
    menus: {
        main: { section: 'forms', priority: 20 },
    },
    new: {
        attributes: [
            'name',
            'title',
            'code',
            'tags',
            'description',
            'definition',
            'longDescription',
            'contactFirstName',
            'contactLastName',
            'contactEmail',
            'contactPhone',
            'contactJobTitle',
            'contactCalendarUrl',
            'thumbnailImage',
        ],
        content: [
            [
                'tabs',
                [
                    [
                        'edit_ftemplate_form_general_title',
                        [
                            [
                                'row',
                                [
                                    ['iblock', ['!$name!', '$code', '$title', '$tags']],
                                    ['bblock', ['$description', '$longDescription']],
                                ],
                            ],
                        ],
                    ],
                    [
                        'edit_ftemplate_form_contact_title',
                        [
                            [
                                'row',
                                [
                                    [
                                        'iblock',
                                        [
                                            '$contactFirstName',
                                            '$contactLastName',
                                            '$contactEmail',
                                            '$contactPhone',
                                            '$contactJobTitle',
                                            '$contactCalendarUrl',
                                        ],
                                    ],
                                ],
                            ],
                        ],
                    ],
                    ['edit_ftemplate_form_images_title', [['row', [['iblock', ['$thumbnailImage']]]]]],
                    ['edit_ftemplate_form_definition_title', [['row', [['block', ['$ftemplateDefinition!']]]]]],
                ],
            ],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name',
            'code',
            'title',
            'tags',
            'description',
            'definition',
            'longDescription',
            'contactFirstName',
            'contactLastName',
            'contactEmail',
            'contactPhone',
            'contactJobTitle',
            'contactCalendarUrl',
            'thumbnailImage',
        ],
        content: [
            [
                'tabs',
                [
                    [
                        'edit_ftemplate_form_general_title',
                        [
                            [
                                'row',
                                [
                                    ['iblock', ['!$name!', '$code', '$title', '$tags']],
                                    ['bblock', ['$description', '$longDescription']],
                                ],
                            ],
                        ],
                    ],
                    [
                        'edit_ftemplate_form_contact_title',
                        [
                            [
                                'row',
                                [
                                    [
                                        'iblock',
                                        [
                                            '$contactFirstName',
                                            '$contactLastName',
                                            '$contactEmail',
                                            '$contactPhone',
                                            '$contactJobTitle',
                                            '$contactCalendarUrl',
                                        ],
                                    ],
                                ],
                            ],
                        ],
                    ],
                    ['edit_ftemplate_form_images_title', [['row', [['iblock', ['$thumbnailImage']]]]]],
                    ['edit_ftemplate_form_definition_title', [['row', [['block', ['$ftemplateDefinition!']]]]]],
                ],
            ],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: ['id', 'name', 'code', 'title', 'description', 'contactEmail'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'name', label: 'column_name_label', flex: 0, width: 150 } as any,
            { id: 'title', label: 'column_title_label', flex: 1 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            all: {},
            public: {},
            reusable: {},
        }
    },
};

export default t;
