const t = {
    icon: 'fa-square-poll-horizontal',
    menus: {
        main: { section: 'forms', priority: 50 },
    },
    new: {
        attributes: ['run'],
        content: [['row', [['block', ['!$run!']]]], '$submit'],
    },
    edit: {
        attributes: ['run'],
        content: [['row', [['block', ['$run:readonly!']]]], '$submit'],
    },
    display: {
        properties: ['id', 'run', 'form', 'status'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'run', label: 'column_run_label', component: 'run', flex: 0, width: 200 } as any,
            { id: 'form', label: 'column_form_label', component: 'form', flex: 0, width: 200 } as any,
            { id: 'version', label: 'column_version_label', component: 'version', flex: 0 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 120 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
