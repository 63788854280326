export const FIND_VERSIONS = (gql: any) => gql`
    query findVersions($offset: String, $limit: Int) {
        findVersions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                status
                createdAt
                updatedAt
            }
        }
    }
`;
export const GET_VERSION = (gql: any) => gql`
    query getVersion($id: ID!) {
        getVersion(id: $id) {
            id
            createdAt
            updatedAt
            status
            form
            definition
            runs {
                items {
                    id
                    status
                }
            }
            solutions {
                items {
                    id
                    status
                }
            }
        }
    }
`;
export const UPDATE_VERSION = (gql: any) => gql`
    mutation updateVersion($id: ID!, $data: UpdateVersionInput!) {
        updateVersion(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_VERSION = (gql: any) => gql`
    mutation deleteVersion($id: ID!) {
        deleteVersion(id: $id) {
            id
        }
    }
`;