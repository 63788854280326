const t = {
    icon: 'fa-gear',
    menus: {
        main: { section: 'computings', priority: 40 },
    },
    new: {
        attributes: ['id'],
        content: [['row', [['block', []]]], '$submit'],
    },
    edit: {
        attributes: [],
        content: [['row', [['block', []]]], '$submit'],
    },
    display: {
        properties: ['id'],
        tabs: [{ name: 'infos' }, { name: 'input' }, { name: 'output' }, { name: 'error' }],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'id', label: 'column_id_label', flex: 0, width: 150 } as any,
            { id: 'type', label: 'column_type_label' } as any,
            {
                id: 'organizationName',
                label: 'column_organizationname_label',
                component: 'organization',
                flex: 0,
                width: 150,
            } as any,
            {
                id: 'platformName',
                label: 'column_platformname_label',
                component: 'platform',
                flex: 0,
                width: 150,
            } as any,
            {
                id: 'status',
                label: 'column_status_label',
                component: 'status',
                format: 'status',
                flex: 0,
                width: 150,
            } as any,
        ],
        globalActions: [],
        actions: ['display'],
        filters: {
            completed: {},
            running: {},
            failed: {},
            all: {},
        },
    },
};

export default t;
