import { red } from '@material-ui/core/colors';
import buildMenus from '@genstackio/react-admin-core/lib/utils/buildMenus';
import crudRoutes from '@genstackio/react-admin-core/lib/utils/crudRoutes';
import buildBreadcrumbs from '@genstackio/react-admin-core/lib/utils/buildBreadcrumbs';
import buildUploader from '@genstackio/react-admin-core/lib/utils/buildUploader';
import buildLists from '@genstackio/react-admin-core/lib/utils/buildLists';
import * as types from './types';
import defaultCleanData from '@genstackio/react-admin-core/lib/utils/cleanData';
import logoPng from './assets/images/logo.png';
import * as cellComponents from './cells';
import buildExpressionContextValueForMozjexl from '@genstackio/react-admin-ui/lib/utils/buildExpressionContextValueForMozjexl'

export const app = {
    prefix: 'computo_admin',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    locales: ['fr-FR', 'en-US', 'de-DE', 'es-ES', 'it-IT'],
    requiredRoles: ['admin'],
};
export const apiOptions = {
    timeout: 15000,
};
export const logos = {
    logo: { url: logoPng, alt: 'Computo' },
    logoFull: { url: logoPng, alt: 'Computo' },
};
export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: '#FBBD2A',
            },
            secondary: {
                main: '#2663E9',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: 'theme-color1',
};
export const ambiance = {
    sectionHeaderCorner: 'rounded-xxsmall',
    menuItemCorner: 'rounded-xxsmall',
    menuFoldCorner: 'rounded-xxsmall',
    buttonCorner: 'rounded-xxsmall',
};
export const routes = [
    { path: '/user/forgot-password', screen: 'forgot_password', secured: false },
    {
        path: '/',
        exact: false,
        screen: 'app',
        routes: [
            { path: '/', screen: 'home', exact: true },
            {
                path: '/',
                exact: false,
                screen: 'crud_app',
                routes: [
                    ...crudRoutes(types),
                    { path: '/user/profile/edit', screen: 'user/edit_current_user' },
                    { path: '/user/profile', screen: 'user/user_profile' },
                    { screen: 'not_found', exact: false, secured: false },
                ],
            },
            { screen: 'not_found', exact: false, secured: false },
        ],
    },
    { screen: 'not_found', exact: false, secured: false },
];

export const lists = buildLists(types);
export const breadcrumbs = buildBreadcrumbs(types, ({ t }: any) => ({
    'user-profile': [
        { label: t('screen_home_label'), target: '/' },
        { label: t('screen_user_profile_label'), target: '/user/profile' },
    ],
    default: [{ label: t('screen_home_label'), target: '/' }],
}));
export const menus = buildMenus(types, {
    main: {
        accounts: {},
        forms: {},
        computings: {},
        referentials: {},
        integrations: {},
    },
    user: {
        '-': {
            items: ({ t }: { t: Function }) => [{ label: t('user_menu_profile_label'), target: '/user/profile' }],
        },
    },
});
export const table = { cellComponents };

export * as queries from './queries';
export { default as fields } from './fields';
export { default as icons } from './icons';
export { default as translations } from './translations';
export { default as importers } from './importers';
export const cleanData = (data: any, keptKeys: any, createMode: boolean) => {
    const v = defaultCleanData(data, keptKeys, createMode);
    if (v && v.definition && 'object' === typeof v.definition && v.definition.content) {
        v.definition = v.definition.content;
    }
    return v;
};

export const expression = buildExpressionContextValueForMozjexl()