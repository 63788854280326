export const CREATE_FTEMPLATE = (gql: any) => gql`
    mutation createFtemplate($data: CreateFtemplateInput!) {
        createFtemplate(data: $data) {
            id
        }
    }
`;
export const DELETE_FTEMPLATE = (gql: any) => gql`
    mutation deleteFtemplate($id: ID!) {
        deleteFtemplate(id: $id) {
            id
        }
    }
`;
export const FIND_FTEMPLATES = (gql: any) => gql`
    query findFtemplates($offset: String, $limit: Int) {
        findFtemplates(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                title
                createdAt
                updatedAt
                status
                publicCatalogStatus
                reusableCatalogStatus
            }
        }
    }
`;
export const FIND_ALL_FTEMPLATES = (gql: any) => gql`
    query ($offset: String, $limit: Int) {
        findAllFtemplates: findFtemplates(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                title
                createdAt
                updatedAt
                status
                publicCatalogStatus
                reusableCatalogStatus
            }
        }
    }
`;
export const FIND_PUBLIC_FTEMPLATES = (gql: any) => gql`
    query ($offset: String, $limit: Int) {
        findPublicFtemplates: findFtemplatesPublishedInPublicCatalog(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                title
                createdAt
                updatedAt
                status
                publicCatalogStatus
                reusableCatalogStatus
            }
        }
    }
`;
export const FIND_REUSABLE_FTEMPLATES = (gql: any) => gql`
    query ($offset: String, $limit: Int) {
        findReusableFtemplates: findFtemplatesPublishedInReusableCatalog(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                title
                createdAt
                updatedAt
                status
                publicCatalogStatus
                reusableCatalogStatus
            }
        }
    }
`;
export const GET_FTEMPLATE = (gql: any) => gql`
    query getFtemplate($id: ID!) {
        getFtemplate(id: $id) {
            id
            name
            code
            title
            description
            createdAt
            updatedAt
            status
            definition
            longDescription
            contactFirstName
            contactLastName
            contactEmail
            contactPhone
            contactJobTitle
            contactCalendarUrl
            thumbnailImage {
                url
            }
            tags
            publicCatalogStatus
            reusableCatalogStatus
        }
    }
`;
export const UPDATE_FTEMPLATE = (gql: any) => gql`
    mutation updateFtemplate($id: ID!, $data: UpdateFtemplateInput!) {
        updateFtemplate(id: $id, data: $data) {
            id
        }
    }
`;