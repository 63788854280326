import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function FormCell({ row: { form } }: FormCellProps) {
    const history = useHistory();
    const formUri = `/forms/${form}`;

    const onFormClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(formUri);
        },
        [history, formUri],
    );

    return (
        <>
            <a href={formUri} onClick={onFormClick} className={'hover:underline'}>
                {form}
            </a>
        </>
    );
}

export type FormCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default FormCell;
