const t = {
    icon: 'fa-file',
    menus: {
        main: { section: 'forms', priority: 50 },
    },
    new: {
        attributes: ['form'],
        content: [['row', [['block', ['$form!']]]], '$submit'],
    },
    edit: {
        attributes: [],
        content: [['row', [['block', ['$form:readonly!']]]], '$submit'],
    },
    display: {
        tabs: [{ name: 'infos' }, { name: 'definition' }, { name: 'runs' }, { name: 'solutions' }],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'id', label: 'column_id_label', flex: 0, width: 300 } as any,
            { id: 'form', label: 'column_form_label', component: 'form', flex: 0, width: 300 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
