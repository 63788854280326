export const CREATE_FIMAGE = (gql: any) => gql`
    mutation createFimage($data: CreateFimageInput!) {
        createFimage(data: $data) {
            id
        }
    }
`;
export const DELETE_FIMAGE = (gql: any) => gql`
    mutation deleteFimage($id: ID!) {
        deleteFimage(id: $id) {
            id
        }
    }
`;
export const FIND_FIMAGES = (gql: any) => gql`
    query findFimages($offset: String, $limit: Int) {
        findFimages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                mainImage {
                    url
                }
                title
                ftemplate
                ftemplateName
                ftemplateCode
                description
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const GET_FIMAGE = (gql: any) => gql`
    query getFimage($id: ID!) {
        getFimage(id: $id) {
            createdAt
            description
            ftemplate
            ftemplateName
            ftemplateCode
            id
            inlinePreset
            mainImage {
                url
            }
            presets
            privateToken
            publicToken
            status
            tags
            title
            token
            updatedAt
        }
    }
`;
export const UPDATE_FIMAGE = (gql: any) => gql`
    mutation updateFimage($id: ID!, $data: UpdateFimageInput!) {
        updateFimage(id: $id, data: $data) {
            id
        }
    }
`;