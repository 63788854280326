const t = {
    icon: 'fa-paperclip',
    menus: {
        main: { section: 'forms', priority: 60 },
    },
    new: {
        attributes: ['form', 'mainFile', 'mainImage', 'tags'],
        content: [['row', [['block', ['$form!', '$mainImage', '$mainFile', '$tags']]]], '$submit'],
    },
    edit: {
        attributes: ['form', 'mainFile', 'mainImage', 'tags'],
        content: [['row', [['block', ['$form!', '$mainImage', '$mainFile', '$tags']]]], '$submit'],
    },
    display: {
        properties: ['form', 'mainFile', 'mainImage', 'status'],
    },
    list: { 
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 200, component: 'date' },
            { id: 'form', label: 'column_form_label', component: 'form', flex: 0, width: 300 },
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
