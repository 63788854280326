import OperationType from '../molecules/OperationType';

const t = {
    icon: 'fa-microchip',
    menus: {
        main: { section: 'computings', priority: 50 },
    },
    new: {
        attributes: [
            'code',
            'name',
            'platform',
            'globalAssumeRole',
            'divideAssumeRole',
            'computeAssumeRole',
            'reduceAssumeRole',
            'divideResource',
            'computeResource',
            'reduceResource',
        ],
        content: [
            [
                'row',
                [
                    [
                        'block',
                        [
                            '!$code!',
                            '$name!',
                            '$platform!',
                            '$globalAssumeRole',
                            '$divideAssumeRole',
                            '$computeAssumeRole',
                            '$reduceAssumeRole',
                            '$divideResource',
                            '$computeResource',
                            '$reduceResource',
                        ],
                    ],
                ],
            ],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code',
            'name',
            'platform',
            'globalAssumeRole',
            'divideAssumeRole',
            'computeAssumeRole',
            'reduceAssumeRole',
            'divideResource',
            'computeResource',
            'reduceResource',
        ],
        content: [
            [
                'row',
                [
                    [
                        'block',
                        [
                            '!$code!',
                            '$name!',
                            '$platform!',
                            '$globalAssumeRole',
                            '$divideAssumeRole',
                            '$computeAssumeRole',
                            '$reduceAssumeRole',
                            '$divideResource',
                            '$computeResource',
                            '$reduceResource',
                        ],
                    ],
                ],
            ],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code',
            'name',
            'platform',
            'globalAssumeRole',
            'divideAssumeRole',
            'computeAssumeRole',
            'reduceAssumeRole',
            'divideResource',
            'computeResource',
            'reduceResource',
        ],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            {
                id: 'organizationName',
                label: 'column_organizationname_label',
                component: 'organization',
                flex: 0,
                width: 150,
            } as any,
            {
                id: 'platformName',
                label: 'column_platformname_label',
                component: 'platform',
                flex: 0,
                width: 150,
            } as any,
            { id: 'name', label: 'column_name_label' } as any,
            { id: 'code', label: 'column_code_label', flex: 0, width: 200 } as any,
            {
                id: 'type',
                label: 'column_type_label',
                flex: 0,
                width: 112,
                render: (_: any, params: any) => <OperationType operation={params.row} />,
            } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
