import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function VersionCell({ row: { version } }: VersionCellProps) {
    const history = useHistory();
    const versionUri = `/versions/${version}`;

    const onVersionClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(versionUri);
        },
        [history, versionUri],
    );

    return (
        <>
            <a href={versionUri} onClick={onVersionClick} className={'hover:underline'}>
                {version}
            </a>
        </>
    );
}

export type VersionCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default VersionCell;
