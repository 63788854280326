export const CREATE_LISTING = (gql: any) => gql`
    mutation createListing($data: CreateListingInput!) {
        createListing(data: $data) {
            id
        }
    }
`;
export const DELETE_LISTING = (gql: any) => gql`
    mutation deleteListing($id: ID!) {
        deleteListing(id: $id) {
            id
        }
    }
`;
export const FIND_LISTINGS = (gql: any) => gql`
    query findListings($offset: String, $limit: Int) {
        findListings(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                items
                name
                privateToken
                publicToken
                status
                token
                updatedAt
            }
        }
    }
`;
export const GET_LISTING = (gql: any) => gql`
    query getListing($id: ID!) {
        getListing(id: $id) {
            code
            createdAt
            id
            items
            name
            privateToken
            publicToken
            status
            token
            updatedAt
        }
    }
`;
export const UPDATE_LISTING = (gql: any) => gql`
    mutation updateListing($id: ID!, $data: UpdateListingInput!) {
        updateListing(id: $id, data: $data) {
            id
        }
    }
`;