const t = {
    icon: 'fa-ruler',
    menus: {
        main: { section: 'referentials', priority: 10 },
    },
    new: {
        attributes: ['code', 'name', 'units'],
        content: [['row', [['block', ['$code!', '$name!', '$units']]]], '$submit'],
    },
    edit: {
        attributes: ['code', 'name', 'units'],
        content: [['row', [['block', ['$code!', '$name!', '$units']]]], '$submit'],
    },
    display: {
        properties: ['id', 'code', 'name', 'status', 'units'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'code', label: 'column_code_label', flex: 1 } as any,
            { id: 'name', label: 'column_name_label', flex: 1 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
