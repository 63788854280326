import { BaseCell } from '../common-types';
import { useTranslation } from 'react-i18next';

export function StatusCell({ value }: StatusCellProps) {
    const { t } = useTranslation();

    switch (value) {
        case 'CREATED':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-green-500 text-white'}>{t(['status_created', value])}</div>
                </div>
            );
        case 'COMPLETED':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-green-500 text-white'}>
                        {t(['status_completed', value])}
                    </div>
                </div>
            );
        case 'PROCESSED':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-green-500 text-white'}>
                        {t(['status_processed', value])}
                    </div>
                </div>
            );
        case 'FAILED':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-red-500 text-white'}>{t(['status_failed', value])}</div>
                </div>
            );
        case 'RUNNING':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-yellow-600 text-white'}>{t(['status_running', value])}</div>
                </div>
            );
        case 'PROCESSING':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-yellow-600 text-white'}>{t(['status_processing', value])}</div>
                </div>
            );
        case 'QUEUED':
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm bg-yellow-300 text-white'}>{t(['status_queued', value])}</div>
                </div>
            );
        default:
            return (
                <div className="flex justify-center w-full">
                    <div className={'rounded p-1 text-sm text-gray-500'}>
                        {value !== null ? value.toLowerCase() : ''}
                    </div>
                </div>
            );
    }
}

export type StatusCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default StatusCell;
