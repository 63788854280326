import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function PlatformCell({ row: { platform, platformName } }: PlatformCellProps) {
    const history = useHistory();
    const platformUri = `/platforms/${platform}`;

    const onPlatformClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(platformUri);
        },
        [history, platformUri],
    );

    return (
        <>
            <a href={platformUri} onClick={onPlatformClick} className={'hover:underline'}>
                {platformName}
            </a>
        </>
    );
}

export type PlatformCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default PlatformCell;
