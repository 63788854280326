export const CREATE_ORGANIZATION = (gql: any) => gql`
    mutation createOrganization($data: CreateOrganizationInput!) {
        createOrganization(data: $data) {
            id
        }
    }
`;
export const DELETE_ORGANIZATION = (gql: any) => gql`
    mutation deleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            id
        }
    }
`;
export const FIND_ORGANIZATIONS = (gql: any) => gql`
    query findOrganizations($offset: String, $limit: Int) {
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                createdAt
                updatedAt
            }
        }
    }
`;
export const GET_ORGANIZATION = (gql: any) => gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            id
            code
            name
            status
            createdAt
            updatedAt
            code
        }
    }
`;
export const GET_ORGANIZATION_BY_CODE = (gql: any) => gql`
    query getOrganizationByCode($code: String!) {
        getOrganizationByCode(code: $code) {
            id
            code
            name
        }
    }
`;
export const UPDATE_ORGANIZATION = (gql: any) => gql`
    mutation updateOrganization($id: ID!, $data: UpdateOrganizationInput!) {
        updateOrganization(id: $id, data: $data) {
            id
        }
    }
`;