export const DELETE_JOB = (gql: any) => gql`
    mutation deleteJob($id: ID!) {
        deleteJob(id: $id) {
            id
        }
    }
`;
export const FIND_JOBS = (gql: any) => gql`
    query findJobs($offset: String, $limit: Int) {
        findJobs(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_COMPLETED_JOBS = (gql: any) => gql`
    query findCompletedJobs($offset: String, $limit: Int) {
        findCompletedJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organizationName
                platformName
                organization
                platform
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_RUNNING_JOBS = (gql: any) => gql`
    query findRunningJobs($offset: String, $limit: Int) {
        findRunningJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organizationName
                platformName
                organization
                platform
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_QUEUED_JOBS = (gql: any) => gql`
    query findQueuedJobs($offset: String, $limit: Int) {
        findQueuedJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_FAILED_JOBS = (gql: any) => gql`
    query findFailedJobs($offset: String, $limit: Int) {
        findFailedJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_ABORTED_JOBS = (gql: any) => gql`
    query findAbortedJobs($offset: String, $limit: Int) {
        findAbortedJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_ARCHIVED_JOBS = (gql: any) => gql`
    query findArchivedJobs($offset: String, $limit: Int) {
        findArchivedJobs(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const FIND_ALL_JOBS = (gql: any) => gql`
    query findAllJobs($offset: String, $limit: Int) {
        findAllJobs(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                createdAt
                updatedAt
                organization
                platform
                organizationName
                platformName
                operation
                operationName
                operationStatus
            }
        }
    }
`;
export const GET_JOB = (gql: any) => gql`
    query getJob($id: ID!) {
        getJob(id: $id) {
            id
            status
            createdAt
            updatedAt
            operationStatus
            results {
                content
            }
            orders {
                content
            }
            tasks {
                items {
                    id
                    createdAt
                    type
                    status
                    operationCode
                    operationName
                    platformCode
                    platformName
                    organizationCode
                    organizationName
                    input {
                        content
                    }
                    output {
                        content
                    }
                    statusDetails {
                        content
                    }
                }
            }
        }
    }
`;
export const UPDATE_JOB = (gql: any) => gql`
    mutation updateJob($id: ID!, $data: UpdateJobInput!) {
        updateJob(id: $id, data: $data) {
            id
        }
    }
`;