const t = {
    icon: 'fa-paperclip',
    menus: {
        main: { section: 'computings', priority: 55 },
    },
    display: {
        properties: ['mainFile', 'mainImage', 'status'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 200, component: 'date' },
            { id: 'solution', label: 'column_solution_label', component: 'solution', flex: 0, width: 300 },
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        actions: ['display'],
    },
};

export default t;
