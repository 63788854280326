export const CREATE_NOTIFICATION = (gql: any) => gql`
    mutation createNotification($data: CreateNotificationInput!) {
        createNotification(data: $data) {
            id
        }
    }
`;
export const DELETE_NOTIFICATION = (gql: any) => gql`
    mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id) {
            id
        }
    }
`;
export const FIND_NOTIFICATIONS = (gql: any) => gql`
    query findNotifications($offset: String, $limit: Int) {
        findNotifications(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const GET_NOTIFICATION = (gql: any) => gql`
    query getNotification($id: ID!) {
        getNotification(id: $id) {
            id
            createdAt
            status
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            draw
            drawCode
            promise
            sale
            template
            recipient
            sender
            variables
            type
            locale
            country
            subject
            attachments
            bodyFile {
                available
                url
            }
            bodyTextFile {
                available
                url
            }
        }
    }
`;
export const UPDATE_NOTIFICATION = (gql: any) => gql`
    mutation updateNotification($id: ID!, $data: UpdateNotificationInput!) {
        updateNotification(id: $id, data: $data) {
            id
        }
    }
`;