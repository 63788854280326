const fields = {
    attachments: { type: 'textarea', kind: 'attachments' },
    bodyFile: { type: 'file', kind: 'bodyFile' },
    bodyTextFile: { type: 'file', kind: 'bodyTextFile' },
    content: { type: 'textarea', kind: 'content' },
    customCode: { type: 'text', kind: 'customCode' },
    data: { type: 'textarea', kind: 'data' },
    definition: { type: 'json', kind: 'definition' },
    definitionFormat: {
        type: 'select',
        kind: 'definitionFormat',
        values: [
            { value: 'json', label: 'json' },
            { value: 'raw', label: 'raw' },
        ],
    },
    details: { type: 'textarea', kind: 'details' },
    features: {
        type: 'featuresSelect',
        kind: 'features',
        values: [
            { value: 'promise', label: 'feature_promise' },
            { value: 'stripe', label: 'feature_stripe' },
            { value: 'paypal', label: 'feature_paypal' },
            { value: 'lydia', label: 'feature_lydia' },
        ],
    },
    form: { type: 'querySelect', kind: 'form' },
    frequency: {
        type: 'select',
        kind: 'frequency',
        values: [
            { value: 'hour_quarterly', label: 'hour_quarterly' },
            { value: 'hourly', label: 'hourly' },
            { value: 'daily', label: 'daily' },
            { value: 'weekly', label: 'weekly' },
            { value: 'biweekly', label: 'biweekly' },
            { value: 'monthly', label: 'monthly' },
            { value: 'trimesterly', label: 'trimesterly' },
            { value: 'semesterly', label: 'semesterly' },
            { value: 'yearly', label: 'yearly' },
            { value: 'none', label: 'none' },
        ],
    },
    ftemplate: { type: 'querySelect', kind: 'ftemplate' },
    run: { type: 'querySelect', kind: 'run' },
    infos: { type: 'textarea', kind: 'infos' },
    index: { type: 'text', kind: 'index' },
    token: { type: 'text', kind: 'token' },
    notificationType: {
        type: 'typeSelect',
        kind: 'type',
        values: [
            { value: 'email', label: 'type_email_label' },
            { value: 'sms', label: 'type_sms_label' },
            { value: 'push', label: 'type_push_label' },
        ],
    },
    options: { type: 'textarea', kind: 'options' },
    organization: {
        type: 'querySelect',
        kind: 'organization',
        convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }),
    },
    owner: {
        type: 'querySelect',
        kind: 'owner',
        query: 'FIND_USERS',
        dataKey: 'findUsers',
        convert: (item: any) => ({
            value: item.id,
            label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email,
        }),
    },
    params: { type: 'textarea', kind: 'params' },
    platform: {
        type: 'querySelect',
        kind: 'platform',
        convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }),
    },
    period: { type: 'text', kind: 'period' },
    permanent: {
        type: 'select',
        kind: 'permanent',
        values: [
            { value: true, label: 'permanent_enabled_label' },
            { value: false, label: 'permanent_disabled_label' },
        ],
    },
    recipient: { type: 'text', kind: 'recipient' },
    role: {
        type: 'roleSelect',
        kind: 'role',
        values: [
            { value: 'user', label: 'role_user_label' },
            { value: 'admin', label: 'role_admin_label' },
        ],
    },
    sender: { type: 'text', kind: 'sender' },
    strategy: { type: 'text', kind: 'strategy' },
    template: { type: 'text', kind: 'template' },
    tenant: { type: 'querySelect', kind: 'tenant' },
    user: {
        type: 'querySelect',
        kind: 'user',
        convert: (item: any) => ({
            value: item.id,
            label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email,
        }),
    },
    value: { type: 'text', kind: 'value' },
    longDescription: { type: 'textarea', kind: 'longDescription' },
    contactFirstName: { type: 'text', kind: 'contactFirstName' },
    contactLastName: { type: 'text', kind: 'contactLastName' },
    contactEmail: { type: 'text', kind: 'contactEmail' },
    contactPhone: { type: 'text', kind: 'contactPhone' },
    contactJobTitle: { type: 'text', kind: 'contactJobTitle' },
    contactCalendarUrl: { type: 'text', kind: 'contactCalendarUrl' },
    intValue: { type: 'number', kind: 'intValue' },
    bigintValue: { type: 'number', kind: 'bigintValue' },
    floatValue: { type: 'number', kind: 'floatValue' },
    boolValue: { type: 'switch', kind: 'boolValue' },
    jsonValue: { type: 'json', kind: 'jsonValue' },
    variables: { type: 'textarea', kind: 'variables' },
    version: {
        type: 'querySelect',
        kind: 'version',
        convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }),
    },
    code: { type: 'text', kind: 'code' },
    name: { type: 'text', kind: 'name' },
    pattern: { type: 'text', kind: 'pattern' },
    globalAssumeRole: { type: 'text', kind: 'globalAssumeRole' },
    divideAssumeRole: { type: 'text', kind: 'divideAssumeRole' },
    computeAssumeRole: { type: 'text', kind: 'computeAssumeRole' },
    reduceAssumeRole: { type: 'text', kind: 'reduceAssumeRole' },
    divideResource: { type: 'text', kind: 'divideResource' },
    computeResource: { type: 'text', kind: 'computeResource' },
    reduceResource: { type: 'text', kind: 'reduceResource' },
    equation: { type: 'text', kind: 'equation', label: 'field_equation_label'},
    intSwitch: { type: 'select', kind: 'int', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_int_label', className: 'flex-1'},
    stringSwitch: { type: 'select', kind: 'string', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_string_label', className: 'flex-1'},
    emailSwitch: { type: 'select', kind: 'email', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_email_label', className: 'flex-1'},
    phoneSwitch: { type: 'select', kind: 'phone', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_phone_label', className: 'flex-1'},
    uuidSwitch: { type: 'select', kind: 'uuid', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_uuid_label', className: 'flex-1'},
    urlSwitch: { type: 'select', kind: 'url', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_url_label', className: 'flex-1'},
    arnSwitch: { type: 'select', kind: 'arn', values: [
        { value: true, label: 'field_select_switch_enabled_label' },
        { value: false, label: 'field_select_switch_disabled_label' },
    ], label: 'field_switch_arn_label', className: 'flex-1'},
    min: { kind: 'min', type: 'number', label: 'field_min_label'},
    max: { kind: 'max', type: 'number', label: 'field_max_label'},
    minLength: { kind: 'minLength', type: 'number', label: 'field_minlength_label'},
    maxLength: { kind: 'maxLength', type: 'number', label: 'field_maxlength_label'},
    mainFile: { kind: 'MainFile', type: 'file', label: 'field_mainfile_label', placeholder: 'field_mainfile_label'},
    suspended: { type: 'switch', kind: 'suspended', label: 'field_suspended_label' },
};

export default fields;
