const t = {
    icon: 'fa-key',
    menus: {
        main: { section: 'integrations', priority: 55 },
    },
    new: {
        attributes: ['name', 'platform'],
        content: [['row', [['block', ['!$name!', '$platform!']]]], '$submit'],
    },
    edit: {
        attributes: ['name'],
        content: [['row', [['block', ['!$name!', '$platform:readonly', '$token:readonly']]]], '$submit'],
    },
    display: {
        properties: [
            'id',
            'name',
            'token',
            'organization',
            'organizationCode',
            'organizationName',
            'platformCode',
            'platformName',
        ],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            { id: 'name', label: 'column_name_label' } as any,
            { id: 'token', label: 'column_token_label', flex: 0, width: 200 } as any,
            {
                id: 'organizationName',
                label: 'column_organizationname_label',
                component: 'organization',
                flex: 0,
                width: 150,
            } as any,
            {
                id: 'platformName',
                label: 'column_platformname_label',
                component: 'platform',
                flex: 0,
                width: 150,
            } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
