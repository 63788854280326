export const CREATE_PLATFORM = (gql: any) => gql`
    mutation createPlatform($data: CreatePlatformInput!) {
        createPlatform(data: $data) {
            id
        }
    }
`;
export const DELETE_PLATFORM = (gql: any) => gql`
    mutation deletePlatform($id: ID!) {
        deletePlatform(id: $id) {
            id
        }
    }
`;
export const FIND_PLATFORMS = (gql: any) => gql`
    query findPlatforms($offset: String, $limit: Int) {
        findPlatforms(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                email
                organization
                organizationCode
                organizationName
                createdAt
                updatedAt
                webhook
            }
        }
    }
`;
export const GET_PLATFORM = (gql: any) => gql`
    query getPlatform($id: ID!) {
        getPlatform(id: $id) {
            id
            code
            name
            organization
            organizationCode
            organizationName
            createdAt
            updatedAt
            webhook
            status
            email
        }
    }
`;
export const UPDATE_PLATFORM = (gql: any) => gql`
    mutation updatePlatform($id: ID!, $data: UpdatePlatformInput!) {
        updatePlatform(id: $id, data: $data) {
            id
        }
    }
`;