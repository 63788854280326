import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function OperationCell({ row: { operation, operationName } }: OperationCellProps) {
    const history = useHistory();
    const operationUri = `/operations/${operation}`;

    const onOperationClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(operationUri);
        },
        [history, operationUri],
    );

    return (
        <>
            <a href={operationUri} onClick={onOperationClick} className={'hover:underline'}>
                {operationName}
            </a>
        </>
    );
}

export type OperationCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OperationCell;
