import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function SolutionCell({ row: { solution } }: SolutionCellProps) {
    const history = useHistory();
    const solutionUri = `/solutions/${solution}`;

    const onSolutionClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(solutionUri);
        },
        [history, solutionUri],
    );

    return (
        <>
            <a href={solutionUri} onClick={onSolutionClick} className={'hover:underline'}>
                {solution}
            </a>
        </>
    );
}

export type SolutionCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default SolutionCell;
