const t = {
    icon: 'fa-building',
    menus: {
        main: { section: 'integrations', priority: 50 },
    },
    new: {
        attributes: ['code', 'name', 'organization', 'email'],
        content: [['row', [['block', ['!$code!', '$name!', '$organization!', '$email!']]]], '$submit'],
    },
    edit: {
        attributes: ['code', 'name', 'organization', 'email'],
        content: [['row', [['block', ['!$code!', '$name', '$organization', '$email']]]], '$submit'],
    },
    display: {
        properties: ['id', 'code', 'name', 'organization', 'organizationCode', 'email'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 150 },
            { id: 'name', label: 'column_name_label', flex: 0, width: 200 },
            {
                id: 'organizationName',
                label: 'column_organizationname_label',
                component: 'organization',
                flex: 0,
                width: 200,
            },
            { id: 'email', label: 'column_email_label', flex: 0, width: 200 },
            { id: 'webhook', label: 'column_webhook_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
