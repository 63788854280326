export const DELETE_TASK = (gql: any) => gql`
    mutation deleteTask($id: ID!) {
        deleteTask(id: $id) {
            id
        }
    }
`;
export const FIND_TASKS = (gql: any) => gql`
    query findTasks($offset: String, $limit: Int) {
        findTasks(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                status
                createdAt
                updatedAt
                job
                operation
                platform
                organization
                organizationName
                platformName
                operationName
            }
        }
    }
`;
export const FIND_COMPLETED_TASKS = (gql: any) => gql`
    query findCompletedTasks($offset: String, $limit: Int) {
        findCompletedTasks(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                type
                status
                createdAt
                updatedAt
                job
                operation
                organization
                organizationName
                platform
                platformName
                operationName
            }
        }
    }
`;
export const FIND_RUNNING_TASKS = (gql: any) => gql`
    query findRunningTasks($offset: String, $limit: Int) {
        findRunningTasks(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                type
                status
                createdAt
                updatedAt
                job
                operation
                organization
                organizationName
                platform
                platformName
                operationName
            }
        }
    }
`;
export const FIND_FAILED_TASKS = (gql: any) => gql`
    query findFailedTasks($offset: String, $limit: Int) {
        findFailedTasks(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                type
                status
                createdAt
                updatedAt
                job
                operation
                organization
                organizationName
                platformName
                platform
                operationName
            }
        }
    }
`;
export const FIND_ALL_TASKS = (gql: any) => gql`
    query findAllTasks($offset: String, $limit: Int) {
        findAllTasks(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                status
                createdAt
                updatedAt
                job
                operation
                organization
                organizationName
                platform
                platformName
                operationName
            }
        }
    }
`;
export const GET_TASK = (gql: any) => gql`
    query getTask($id: ID!) {
        getTask(id: $id) {
            id
            createdAt
            updatedAt
            type
            status
            operationCode
            operationName
            platformCode
            platformName
            operation
            organizationCode
            organizationName
            input {
                content
            }
            output {
                content
            }
            statusDetails {
                content
            }
        }
    }
`;
export const UPDATE_TASK = (gql: any) => gql`
    mutation updateTask($id: ID!, $data: UpdateTaskInput!) {
        updateTask(id: $id, data: $data) {
            id
        }
    }
`;