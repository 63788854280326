const importers = {
    body: [({ path }: any) => import(`./bodies/${path}Body`), ['notification']],
    content: [({ path }: any) => import(`./contents/${path}Content`), ['iblock', 'bblock']],
    tab: [
        ({ path }: any) => import(`./tabs/${path}Tab`),
        [
            'job/infos',
            'job/orders',
            'job/results',
            'job/tasks',
            'organization/infos',
            'task/infos',
            'task/input',
            'task/output',
            'task/error',
            'form/infos',
            'form/definition',
            'form/versions',
            'version/infos',
            'version/definition',
            'version/runs',
            'version/infos',
            'version/definition',
            'version/runs',
            'version/solutions',
        ],
    ],
    toolbar: [({ path }: any) => import(`./toolbars/${path}Toolbar`), ['main_app', 'notification', 'ftemplate']],
    form_field: [({ path }: any) => import(`./fields/${path}Field`), ['ftemplateDefinition', 'units', 'items', 'constraints', 'tags']],
};

export default importers;
