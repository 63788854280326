export const CREATE_TEMPLATE = (gql: any) => gql`
    mutation createTemplate($data: CreateTemplateInput!) {
        createTemplate(data: $data) {
            id
        }
    }
`;
export const DELETE_TEMPLATE = (gql: any) => gql`
    mutation deleteTemplate($id: ID!) {
        deleteTemplate(id: $id) {
            id
        }
    }
`;
export const FIND_TEMPLATES = (gql: any) => gql`
    query findTemplates($offset: String, $limit: Int) {
        findTemplates(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                name
                privateToken
                publicToken
                status
                token
                type
                updatedAt
            }
        }
    }
`;
export const GET_TEMPLATE = (gql: any) => gql`
    query getTemplate($id: ID!) {
        getTemplate(id: $id) {
            code
            createdAt
            id
            name
            privateToken
            publicToken
            status
            token
            type
            updatedAt
        }
    }
`;
export const UPDATE_TEMPLATE = (gql: any) => gql`
    mutation updateTemplate($id: ID!, $data: UpdateTemplateInput!) {
        updateTemplate(id: $id, data: $data) {
            id
        }
    }
`;