export const CREATE_SFILE = (gql: any) => gql`
    mutation createSfile($data: CreateSfileInput!) {
        createSfile(data: $data) {
            id
        }
    }
`;
export const DELETE_SFILE = (gql: any) => gql`
    mutation deleteSfile($id: ID!) {
        deleteSfile(id: $id) {
            id
        }
    }
`;
export const FIND_SFILES = (gql: any) => gql`
    query findSfiles($offset: String, $limit: Int) {
        findSfiles(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                mainImage {
                    url
                }
                mainFile {
                    url
                }
                solution
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const GET_SFILE = (gql: any) => gql`
    query getSfile($id: ID!) {
        getSfile(id: $id) {
            id
            mainImage {
                url
            }
            mainFile {
                url
            }
            solution
            createdAt
            updatedAt
            status
        }
    }
`;
export const UPDATE_SFILE = (gql: any) => gql`
    mutation updateSfile($id: ID!, $data: UpdateSfile!) {
        updateSfile(id: $id, data: $data) {
            id
        }
    }
`;