const t = {
    icon: 'fa-computer',
    menus: {
        main: { section: 'computings', priority: 35 },
    },
    new: {
        attributes: ['form', 'version'],
        content: [['row', [['block', ['$version!']]]], '$submit'],
    },
    edit: {
        attributes: [],
        content: [['row', [['block', ['$form:readonly!', '$version:readonly!']]]], '$submit'],
    },
    display: {
        properties: ['id', 'form', 'version', 'definition', 'status'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'form', label: 'column_form_label', component: 'form', flex: 1 } as any,
            { id: 'version', label: 'column_version_label', component: 'version', flex: 1 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: [],
        actions: ['display', 'edit', 'delete'],
        filters: {
            processed: {},
            processing: {},
            queued: {},
            failed: {},
            all: {},
        },
    },
};

export default t;
