export const CREATE_CONSTRAINT = (gql: any) => gql`
    mutation createConstraint($data: CreateConstraintInput!) {
        createConstraint(data: $data) {
            id
        }
    }
`;
export const DELETE_CONSTRAINT = (gql: any) => gql`
    mutation deleteConstraint($id: ID!) {
        deleteConstraint(id: $id) {
            id
        }
    }
`;
export const FIND_CONSTRAINTS = (gql: any) => gql`
    query findConstraints($offset: String, $limit: Int) {
        findConstraints(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                arn
                code
                createdAt
                email
                equation
                id
                int
                max
                maxLength
                min
                minLength
                name
                pattern
                phone
                publicToken
                privateToken
                required
                status
                string
                token
                updatedAt
                url
                uuid
                values
            }
        }
    }
`;
export const GET_CONSTRAINT = (gql: any) => gql`
    query getConstraint($id: ID!) {
        getConstraint(id: $id) {
            arn
            code
            createdAt
            email
            equation
            id
            int
            max
            maxLength
            min
            minLength
            name
            pattern
            phone
            publicToken
            privateToken
            required
            status
            string
            token
            updatedAt
            url
            uuid
            values
        }
    }
`;
export const UPDATE_CONSTRAINT = (gql: any) => gql`
    mutation updateConstraint($id: ID!, $data: UpdateConstraintInput!) {
        updateConstraint(id: $id, data: $data) {
            id
        }
    }
`;