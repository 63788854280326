export const CREATE_FFILE = (gql: any) => gql`
    mutation createFfile($data: CreateFfileInput!) {
        createFfile(data: $data) {
            id
        }
    }
`;
export const DELETE_FFILE = (gql: any) => gql`
    mutation deleteFfile($id: ID!) {
        deleteFfile(id: $id) {
            id
        }
    }
`;
export const FIND_FFILES = (gql: any) => gql`
    query findFfiles($offset: String, $limit: Int) {
        findFfiles(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt
                form
                id
                inlinePreset
                mainFile {
                    url
                }
                mainImage {
                    url
                }
                presets
                status
                tags
            }
        }
    }
`;
export const GET_FFILE = (gql: any) => gql`
    query getFfile($id: ID!) {
        getFfile(id: $id) {
            createdAt
            form
            id
            inlinePreset
            mainFile {
                url
            }
            mainImage {
                url
            }
            presets
            status
            tags
        }
    }
`;
export const UPDATE_FFILE = (gql: any) => gql`
    mutation updateFfile($id: ID!, $data: UpdateFfileInput!) {
        updateFfile(id: $id, data: $data) {
            id
        }
    }
`;