const t = {
    icon: 'fa-image',
    menus: {
        main: { section: 'forms', priority: 30 },
    },
    new: {
        attributes: ['title', 'mainImage', 'description', 'ftemplate'],
        content: [['row', [['block', ['!$title', '$mainImage', '$description', '$ftemplate']]]], '$submit'],
    },
    edit: {
        attributes: ['title', 'mainImage', 'description', 'ftemplate'],
        content: [['row', [['block', ['!$title', '$mainImage', '$description', '$ftemplate']]]], '$submit'],
    },
    display: {
        properties: ['title', 'description', 'ftemplate'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'title', label: 'column_title_label', flex: 1 } as any,
            { id: 'ftemplate', label: 'column_ftemplate_label', component: 'ftemplate', flex: 0, width: 300 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
