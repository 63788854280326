export const CREATE_RUN = (gql: any) => gql`
    mutation createRun($data: CreateRunInput!) {
        createRun(data: $data) {
            id
        }
    }
`;
export const DELETE_RUN = (gql: any) => gql`
    mutation deleteRun($id: ID!) {
        deleteRun(id: $id) {
            id
        }
    }
`;
export const FIND_RUNS = (gql: any) => gql`
    query findRuns($offset: String, $limit: Int) {
        findRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const FIND_PROCESSED_RUNS = (gql: any) => gql`
    query findProcessedRuns($offset: String, $limit: Int) {
        findProcessedRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const FIND_PROCESSING_RUNS = (gql: any) => gql`
    query findProcessingRuns($offset: String, $limit: Int) {
        findProcessingRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const FIND_QUEUED_RUNS = (gql: any) => gql`
    query findQueuedRuns($offset: String, $limit: Int) {
        findQueuedRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const FIND_FAILED_RUNS = (gql: any) => gql`
    query findFailedRuns($offset: String, $limit: Int) {
        findFailedRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const FIND_ALL_RUNS = (gql: any) => gql`
    query findAllRuns($offset: String, $limit: Int) {
        findAllRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                form
                createdAt
                updatedAt
                status
                definition
                version
            }
        }
    }
`;
export const GET_RUN = (gql: any) => gql`
    query getRun($id: ID!) {
        getRun(id: $id) {
            id
            form
            createdAt
            updatedAt
            status
            definition
        }
    }
`;
export const UPDATE_RUN = (gql: any) => gql`
    mutation updateRun($id: ID!, $data: UpdateRunInput!) {
        updateRun(id: $id, data: $data) {
            id
        }
    }
`;