const t = {
    icon: 'fa-building',
    menus: {
        main: { section: 'accounts', priority: 30 },
    },
    new: {
        attributes: ['name', 'code'],
        content: [['row', [['block', ['!$code!', '$name!']]]], '$submit'],
    },
    edit: {
        attributes: ['name', 'code'],
        content: [['row', [['block', ['!$code!', '$name!']]]], '$submit'],
    },
    display: {
        tabs: [{ name: 'infos' }],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
            { id: 'name', label: 'column_name_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
