import Chip from '@material-ui/core/Chip';
import { Grid, Tooltip } from '@material-ui/core';

export const OperationType = ({ operation }: OperationTypeProps) => (
    <div className="w-full">
        <Grid spacing={1} container>
            <Grid item>
                <Tooltip title={'DIVIDE'}>
                    <Chip label={'D'} size={'small'} disabled={!operation.divideResource} />
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={'COMPUTE'}>
                    <Chip label={'C'} size={'small'} disabled={!operation.computeResource} />
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={'REDUCE'}>
                    <Chip label={'R'} size={'small'} disabled={!operation.reduceResource} />
                </Tooltip>
            </Grid>
        </Grid>
    </div>
);

export interface OperationTypeProps {
    operation: any;
}

export default OperationType;
