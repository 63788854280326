export const CREATE_TOKEN = (gql: any) => gql`
    mutation createToken($data: CreateTokenInput!) {
        createToken(data: $data) {
            id
        }
    }
`;
export const DELETE_TOKEN = (gql: any) => gql`
    mutation deleteToken($id: ID!) {
        deleteToken(id: $id) {
            id
        }
    }
`;
export const FIND_TOKENS = (gql: any) => gql`
    query findTokens($offset: String, $limit: Int) {
        findTokens(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                organization
                organizationCode
                organizationName
                platform
                platformCode
                platformName
                name
                createdAt
                updatedAt
                status
                token
            }
        }
    }
`;
export const GET_TOKEN = (gql: any) => gql`
    query getToken($id: ID!) {
        getToken(id: $id) {
            id
            createdAt
            updatedAt
            name
            status
            token
            platform
            platformCode
            platformName
            organization
            organizationCode
            organizationName
        }
    }
`;
export const UPDATE_TOKEN = (gql: any) => gql`
    mutation updateToken($id: ID!, $data: UpdateTokenInput!) {
        updateToken(id: $id, data: $data) {
            id
        }
    }
`;