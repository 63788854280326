export const CREATE_FIELD = (gql: any) => gql`
    mutation createField($data: CreateFieldInput!) {
        createField(data: $data) {
            id
        }
    }
`;
export const DELETE_FIELD = (gql: any) => gql`
    mutation deleteField($id: ID!) {
        deleteField(id: $id) {
            id
        }
    }
`;
export const FIND_FIELDS = (gql: any) => gql`
    query findFields($offset: String, $limit: Int) {
        findFields(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                name
                privateToken
                publicToken
                status
                token
                type
                updatedAt
                constraints
            }
        }
    }
`;
export const GET_FIELD = (gql: any) => gql`
    query getField($id: ID!) {
        getField(id: $id) {
            code
            createdAt
            id
            name
            privateToken
            publicToken
            status
            token
            type
            updatedAt
            constraints
        }
    }
`;
export const UPDATE_FIELD = (gql: any) => gql`
    mutation updateField($id: ID!, $data: UpdateFieldInput!) {
        updateField(id: $id, data: $data) {
            id
        }
    }
`;