const t = {
    icon: 'fa-file-pen',
    plural: 'entries',
    menus: {
        main: { section: 'forms', priority: 60 },
    },
    new: {
        attributes: ['form', 'key', 'type', 'value', 'intValue', 'bigintValue', 'floatValue', 'boolValue', 'jsonValue'],
        content: [
            [
                'row',
                [
                    [
                        'block',
                        [
                            '!$form!',
                            '$key!',
                            '$type',
                            '$value',
                            '$intValue',
                            '$bigintValue',
                            '$floatValue',
                            '$boolValue',
                            '$jsonValue',
                        ],
                    ],
                ],
            ],
            '$submit',
        ],
    },
    edit: {
        attributes: ['form', 'key', 'type', 'value', 'intValue', 'bigintValue', 'floatValue', 'boolValue', 'jsonValue'],
        content: [
            [
                'row',
                [
                    [
                        'block',
                        [
                            '$form:readonly!',
                            '!$key!',
                            '$type!',
                            '$value',
                            '$intValue',
                            '$bigintValue',
                            '$floatValue',
                            '$boolValue',
                            '$jsonValue',
                        ],
                    ],
                ],
            ],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id',
            'key',
            'form',
            'type',
            'value',
            'intValue',
            'bigintValue',
            'floatValue',
            'boolValue',
            'jsonValue',
            'status',
        ],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'id', label: 'column_id_label', flex: 0, width: 300 } as any,
            { id: 'form', label: 'column_form_label', component: 'form', flex: 0, width: 300 } as any,
            { id: 'key', label: 'column_key_label', flex: 1 } as any,
            { id: 'type', label: 'column_type_label', flex: 0, width: 120 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
