const t = {
    icon: 'fa-user-tie',
    menus: {},
    new: {
        attributes: ['code', 'name'],
        content: [['row', [['block', ['!$code!', '$name!']]]], '$submit'],
    },
    edit: {
        attributes: ['code', 'name'],
        content: [['row', [['block', ['!$code!', '$name!']]]], '$submit'],
    },
    display: {
        properties: ['id', 'code', 'name'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 150 } as any,
            { id: 'name', label: 'column_name_label' } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;
