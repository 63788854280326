import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function OrganizationCell({ row: { organization, organizationName } }: OrganizationCellProps) {
    const history = useHistory();
    const organizationUri = `/organizations/${organization}`;

    const onOrganizationClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(organizationUri);
        },
        [history, organizationUri],
    );

    return (
        <>
            <a href={organizationUri} onClick={onOrganizationClick} className={'hover:underline'}>
                {organizationName}
            </a>
        </>
    );
}

export type OrganizationCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default OrganizationCell;
