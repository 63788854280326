export const CREATE_TAG = (gql: any) => gql`
    mutation createTag($data: CreateTagInput!) {
        createTag(data: $data) {
            id
        }
    }
`;
export const DELETE_TAG = (gql: any) => gql`
    mutation deleteTag($id: ID!) {
        deleteTag(id: $id) {
            id
        }
    }
`;
export const FIND_TAGS = (gql: any) => gql`
    query findTags($offset: String, $limit: Int) {
        findTags(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                createdAt
                privateToken
                publicToken
                status
                token
                updatedAt
            }
        }
    }
`;
export const GET_TAG = (gql: any) => gql`
    query getTag($id: ID!) {
        getTag(id: $id) {
            id
            code
            name
            createdAt
            id
            privateToken
            publicToken
            status
            token
            updatedAt
        }
    }
`;
export const UPDATE_TAG = (gql: any) => gql`
    mutation updateTag($id: ID!, $data: UpdateTagInput!) {
        updateTag(id: $id, data: $data) {
            id
        }
    }
`;