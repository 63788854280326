import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function FtemplateCell({ row: { ftemplate, ftemplateName, ftemplateCode } }: FtemplateCellProps) {
    const history = useHistory();
    const ftemplateUri = `/ftemplates/${ftemplate}`;
    const onFtemplateClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(ftemplateUri);
        },
        [history, ftemplateUri],
    );

    const display = ftemplateCode ? (ftemplateName ? `${ftemplateName} (${ftemplateCode})` : ftemplateCode) : ftemplate

    return (
        <>
            <a href={ftemplateUri} onClick={onFtemplateClick} className={'hover:underline'}>
                {display}
            </a>
        </>
    );
}

export type FtemplateCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default FtemplateCell;
