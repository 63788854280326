import { useCallback } from 'react';
import { BaseCell } from '../common-types';
import { useHistory } from 'react-router-dom';

export function RunCell({ row: { run } }: Runprops) {
    const history = useHistory();
    const runUri = `/runs/${run}`;

    const onRunClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(runUri);
        },
        [history, runUri],
    );

    return (
        <>
            <a href={runUri} onClick={onRunClick} className={'hover:underline'}>
                {run}
            </a>
        </>
    );
}

export type Runprops = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default RunCell;
