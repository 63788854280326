export const CREATE_SOLUTION = (gql: any) => gql`
    mutation createSolution($data: CreateSolutionInput!) {
        createSolution(data: $data) {
            id
        }
    }
`;
export const DELETE_SOLUTION = (gql: any) => gql`
    mutation deleteSolution($id: ID!) {
        deleteSolution(id: $id) {
            id
        }
    }
`;
export const FIND_SOLUTIONS = (gql: any) => gql`
    query findSolutions($offset: String, $limit: Int) {
        findSolutions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                run
                form
                createdAt
                updatedAt
                status
                version
            }
        }
    }
`;
export const GET_SOLUTION = (gql: any) => gql`
    query getSolution($id: ID!) {
        getSolution(id: $id) {
            id
            run
            form
            createdAt
            updatedAt
            status
        }
    }
`;
export const UPDATE_SOLUTION = (gql: any) => gql`
    mutation updateSolution($id: ID!, $data: UpdateSolutionInput!) {
        updateSolution(id: $id, data: $data) {
            id
        }
    }
`;