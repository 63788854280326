export const CREATE_OPERATION = (gql: any) => gql`
    mutation createOperation($data: CreateOperationInput!) {
        createOperation(data: $data) {
            id
        }
    }
`;
export const DELETE_OPERATION = (gql: any) => gql`
    mutation deleteOperation($id: ID!) {
        deleteOperation(id: $id) {
            id
        }
    }
`;
export const FIND_OPERATIONS = (gql: any) => gql`
    query findOperations($offset: String, $limit: Int) {
        findOperations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                organization
                organizationName
                code
                name
                platform
                platformName
                globalAssumeRole
                divideAssumeRole
                computeAssumeRole
                reduceAssumeRole
                divideResource
                computeResource
                reduceResource
            }
        }
    }
`;
export const GET_OPERATION = (gql: any) => gql`
    query getOperation($id: ID!) {
        getOperation(id: $id) {
            id
            code
            name
            platform
            globalAssumeRole
            divideAssumeRole
            computeAssumeRole
            reduceAssumeRole
            divideResource
            computeResource
            reduceResource
        }
    }
`;
export const UPDATE_OPERATION = (gql: any) => gql`
    mutation updateOperation($id: ID!, $data: UpdateOperationInput!) {
        updateOperation(id: $id, data: $data) {
            id
        }
    }
`;