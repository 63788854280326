const t = {
    icon: 'fa-hand',
    menus: {
        main: { section: 'referentials', priority: 25 },
    },
    new: {
        attributes: ['code', 'name', 'equation', 'pattern', 'min', 'max', 'minLength', 'maxLength', 'int', 'string', 'email', 'phone', 'uuid', 'url', 'arn'],
        content: [['row', 
            [['block', 
                ['$code!', '$name!', '$equation', '$pattern',
                    ['row', ['$min', '$max', '$minLength', '$maxLength']],
                    ['row', ['$intSwitch', '$stringSwitch', '$emailSwitch', '$phoneSwitch']],
                    ['row', [ '$uuidSwitch', '$urlSwitch', '$arnSwitch']]
                ]
            ]]
        ], '$submit'],
    },
    edit: {
        attributes: ['code', 'name', 'equation', 'pattern', 'min', 'max', 'minLength', 'maxLength', 'int', 'string', 'email', 'phone', 'uuid', 'url', 'arn'],
        content: [['row', 
            [['block', 
                ['$code!', '$name!', '$equation', '$pattern',
                    ['row', ['$min', '$max', '$minLength', '$maxLength']],
                    ['row', ['$intSwitch', '$stringSwitch', '$emailSwitch', '$phoneSwitch']],
                    ['row', [ '$uuidSwitch', '$urlSwitch', '$arnSwitch']]
                ]
            ]]
        ], '$submit'],
    },
    display: {
        properties: ['id', 'code', 'name', 'equation'],
    },
    list: {
        search: false,
        props: {
            searchSwitch: false,
        },
        columns: [
            { id: 'updatedAt', label: 'column_updatedat_label', flex: 0, width: 200, component: 'date' },
            { id: 'code', label: 'column_code_label', flex: 1 } as any,
            { id: 'name', label: 'column_name_label', flex: 1 } as any,
            { id: 'status', label: 'column_status_label', component: 'status', flex: 0, width: 150 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
