export const CREATE_FORM = (gql: any) => gql`
    mutation createForm($data: CreateFormInput!) {
        createForm(data: $data) {
            id
        }
    }
`;
export const DELETE_FORM = (gql: any) => gql`
    mutation deleteForm($id: ID!) {
        deleteForm(id: $id) {
            id
        }
    }
`;
export const FIND_FORMS = (gql: any) => gql`
    query findForms($offset: String, $limit: Int) {
        findForms(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                ftemplate
                ftemplateName
                ftemplateCode
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const GET_FORM = (gql: any) => gql`
    query getForm($id: ID!) {
        getForm(id: $id) {
            id
            name
            ftemplate
            ftemplateName
            ftemplateCode
            createdAt
            updatedAt
            status
            definition
            versions {
                items {
                    id
                    status
                }
            }
        }
    }
`;
export const UPDATE_FORM = (gql: any) => gql`
    mutation updateForm($id: ID!, $data: UpdateFormInput!) {
        updateForm(id: $id, data: $data) {
            id
        }
    }
`;