export const CREATE_ENTRY = (gql: any) => gql`
    mutation createEntry($data: CreateEntryInput!) {
        createEntry(data: $data) {
            id
        }
    }
`;
export const DELETE_ENTRY = (gql: any) => gql`
    mutation deleteEntry($id: ID!) {
        deleteEntry(id: $id) {
            id
        }
    }
`;
export const FIND_ENTRIES = (gql: any) => gql`
    query findEntries($offset: String, $limit: Int) {
        findEntries(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                key
                form
                createdAt
                updatedAt
                status
                type
                value
                intValue
                bigintValue
                floatValue
                boolValue
                jsonValue
                listValue
                intListValue
                bigintListValue
                floatListValue
                boolListValue
                jsonListValue
            }
        }
    }
`;
export const GET_ENTRY = (gql: any) => gql`
    query getEntry($id: ID!) {
        getEntry(id: $id) {
            id
            key
            form
            createdAt
            updatedAt
            status
            type
            value
            intValue
            bigintValue
            floatValue
            boolValue
            jsonValue
            listValue
            intListValue
            bigintListValue
            floatListValue
            boolListValue
            jsonListValue
        }
    }
`;
export const UPDATE_ENTRY = (gql: any) => gql`
    mutation updateEntry($id: ID!, $data: UpdateEntryInput!) {
        updateEntry(id: $id, data: $data) {
            id
        }
    }
`;